<template>
  <insightsComponent status="all" />
</template>

<script>
import insightsComponent from "../../components/insights/insightsList.vue";

export default {
  components: {
    insightsComponent,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
