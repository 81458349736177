<template>
  <vx-card>
    <div class="grid gap-6 grid-cols md:grid-cols-2 lg:p-6">
      <a 
        :href="card.url" 
        v-for="(card, index) in insightsCardList.filter((cardList) => !cardList.isSupplier)" 
        :key="index"
        class="insights-card-list 
              flex 
              flex-col 
              justify-center 
              items-center 
              py-4 
              px-3 
              rounded-lg
              lg:py-6
              lg:flex-row 
              lg:space-x-2" 
      >

        <div class="w-2/12 flex items-center justify-center">
          <img
            :class="`my-2 lg:my-0 w-12 h-12 object-contain insights-icon ${card.className}`"
            :src="card.icon"
          />
        </div>

        <div class="flex-1 text-center lg:text-left">
          <p class="font-semibold"> {{card.title}} </p>
          <p class="text-sm">{{card.description}}</p>
        </div>
        
      </a>
    </div>
  </vx-card>
</template>

<script>
import ClinicIco from '../../../assets/images/pages/insights/clinic.png';
import AppointmentIco from '../../../assets/images/pages/insights/appointment.png';
import NurseIco from '../../../assets/images/pages/insights/nurse.png';
import PatientIco from '../../../assets/images/pages/insights/patient.png';
import ProductIco from '../../../assets/images/pages/insights/product.png';
import TreatmentAreaIco from '../../../assets/images/pages/insights/treatment_area.png';

export default {
  props: {},
  data() {
    return {
      insightsCardList: []
    };
  },
  methods: {
    getUrlParams(urlType){
      const isUrlTypeSupplier = urlType === 'supplier'
      const isSupplier = isUrlTypeSupplier ? isUrlTypeSupplier : false
      if(urlType){
        this.insightsCardList = [
          {
            title: 'Clinics',
            description: 'View trends such as Inactive vs. Active clinics.',
            url: `/${urlType}/insights/clinics`,
            icon: ClinicIco,
            isSupplier: isSupplier,
            className: 'clinics-icon'
          },
          {
            title: 'Treatment Areas',
            description: 'Discover opportunities for new treatments areas.',
            url: `/${urlType}/insights/treatment-area/all/treatments-per-treatment-area`,
            icon: TreatmentAreaIco,
            isSupplier: false,
            className: 'treatment-areas-icon'
          },
          {
            title: 'Nurses',
            description: 'Monitor performance and specialisations of across your team.',
            url: `/${urlType}/insights/nurses`,
            icon: NurseIco,
            isSupplier: isSupplier,
            className: 'nurses-icon'
          },
          {
            title: 'Products',
            description: 'Explore trends about product types and brands.',
            url: `/${urlType}/insights/products`,
            icon: ProductIco,
            isSupplier: false,
            className: 'products-icon'
          },
          {
            title: 'Patient Trends',
            description: 'View patient trends such as age brackets, gender and location to learn more about your customers.',
            url: `/${urlType}/insights/patient-trends`,
            icon: PatientIco,
            isSupplier: false,
            className: 'patient-trends-icon'
          },
          {
            title: 'Appointments',
            description: 'Explore treatment trends across appointments and find new treatment opportunities.',
            url: `/${urlType}/insights/appointments`,
            icon: AppointmentIco,
            isSupplier: false,
            className: 'appointments-icon'
          },
        ]
      }
    },
    async checkIsAnalyticsEnabled() {
      const organizationId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
      const { data } = await this.$store.dispatch(
        "organization/fetchOrganizationDetails",
        organizationId
      );
      const isAnalyticsEnabled = data.data.isAnalyticsEnabled;
      if (!isAnalyticsEnabled) {
        this.$router.push({path: "/org-owner"});
      }
      this.getUrlParams("org-owner");
    },
  },
  mounted() {
    let userRole = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if(userRole && userRole.userType === "superAdmin"){
      this.getUrlParams("super-admin");
    }else if(userRole && userRole.organizations[0].role.toUpperCase() === 
      "supplier".toUpperCase()){
      this.getUrlParams("supplier");
    }else{
      this.checkIsAnalyticsEnabled();
    }
  }
};
</script>

<style scoped>
  .insights-card-list{
    border:1px solid #E7E7E7; 
    background-color:#F9F9F9; 
    color: #3E695C;
  }
</style>
